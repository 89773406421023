import __projectConfig from './project.config.ts'
import __i18n from '~/components/i18n.ts'

// @ts-ignore
export default defineI18nConfig(() => ({
  legacy: false,
  locale: __projectConfig.locale.default,
  messages: {
    ...__i18n,
  },
}))
