export default {
  en: {
    title: 'Admin area',
    draftMode: 'Draft mode',
    logout: 'Logout',
  },
  fr: {
    title: 'Espace admin',
    draftMode: 'Mode brouillon',
    logout: 'Déconnexion',
  },
}
