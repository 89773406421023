export default {
  en: {
    title: 'Admin area',
    password: 'Password',
    login: 'Login',
    logout: 'Logout',
  },
  fr: {
    title: 'Espace admin',
    password: 'Mot de passe',
    login: 'Connexion',
    logout: 'Se déconnecter',
  },
}
